import { graphql } from 'gatsby';
import React from 'react';

import Layout from '../components/site/Layout';
import SEO from '../components/site/SEO';
import Linkedin from '../images/svgs/linkedin.svg';
import Twitter from '../images/svgs/twitter.svg';

export default function RestrictedPage() {
  return (
    <Layout is404>
      <SEO title="Pioneers Prize" meta={[{ name: 'robots', content: 'noindex' }]}></SEO>
      <h1 className="text-center">404</h1>
      <p className="text-center">
        Unfortunately, the Polkadot Pioneers Prize is not available in your region.
        <br />
        If you’d like to learn more about Polkadot or the Pioneers Prize, you can always follow Polkadot on social media
        or visit our{' '}
        <a href="https://polkadot.network/blog/" target="_blank" rel="noreferrer">
          blog
        </a>
        .
      </p>
      <p>
        <a href="https://twitter.com/Polkadot" target="_blank" rel="noreferrer">
          <Twitter className="icon" />
        </a>
        <a href="https://www.linkedin.com/company/polkadot-network/" target="_blank" rel="noreferrer">
          <Linkedin className="icon" />
        </a>
      </p>
    </Layout>
  );
}

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
